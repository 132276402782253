@tailwind base;
@tailwind components;

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  color: #3c4257;
  font-weight: 400;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.spinner-icon ellipse {
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 70;
  stroke-dashoffset: 30;
  fill: transparent;
}

.ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transition: transform 200ms ease-in-out;
  transform: scale(0.8);
}

.ReactModal__Content--after-open {
  transform: scale(1);
}

.ReactModal__Content--before-close {
  transform: scale(0.8);
}

.react-multi-email {
  position: relative;
  flex: 0 0 auto;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(42 47 69 / 16%), 0 0 0 1px rgb(6 122 184 / 0%),
    0 0 0 2px rgb(6 122 184 / 0%), 0 1px 1px rgb(0 0 0 / 8%);
  color: #3c4257;
  cursor: text;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  word-break: normal;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
  user-select: auto;
  padding: 2px 8px;
  min-height: 28px;
  max-height: 190px;
  overflow-y: auto;
  display: flex;
  min-width: 250px;
}

.react-multi-email.focused {
  border: 0;
  box-shadow: 0 0 0 1px rgb(42 47 69 / 0%), 0 0 0 1px rgb(6 122 184 / 20%),
    0 0 0 2px rgb(6 122 184 / 25%), 0 1px 1px rgb(0 0 0 / 8%);
  outline: none;
}

.react-multi-email > input {
  margin-top: 1.5px !important;
  padding: 0 0.1em !important;
  line-height: 20px !important;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
}

.react-multi-email > input:focus {
  --tw-ring-color: transparent !important;
}

.react-multi-email [data-tag] {
  line-height: 16px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
}

.react-multi-email > span[data-placeholder] {
  padding: 0 !important;
}

.OtpInput {
  width: 40px !important;
  height: 52px;
}

.Tooltip {
  border-radius: 4px;
  padding: 8px 12px !important;
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  white-space: normal;
}

.Toastify__toast-container {
  width: auto;
  max-width: 576px;
  padding: 0;
  transition: all 0.5s;
}

.Toastify__toast-container--bottom-center {
  bottom: 16px;
}

.Toastify__toast-container--top-center {
  top: 16px;
}

.Toastify__toast {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08),
    0 3px 6px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  white-space: normal;
  min-height: 0;
}

.Toastify__toast--default {
  background-color: #3c4257;
  color: #ffffff;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__close-button {
  display: none !important;
}

.__react_component_tooltip {
  border-radius: 4px !important;
  padding: 4px 8px !important;
  font-size: 13px !important;
  line-height: 16px !important;
  transition: opacity 0.2s ease-in-out !important;
}

.react-select__control {
  cursor: text;
  background-color: #ffffff;
  border-width: 1px;
  border-color: rgba(209, 213, 219, 1) !important;
  box-shadow: none;
  min-height: 28px;
  border-radius: 4px;
  max-width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  white-space: nowrap;
  text-align: left;
  min-height: 28px !important;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  border-color: rgba(149, 194, 245, 1) !important;
  --tw-ring-opacity: 0.5;
  --tw-ring-color: rgba(192, 218, 249, var(--tw-ring-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.react-select__menu {
  position: relative;
  z-index: 10;
}

.react-select__value-container {
  padding: 0 !important;
}

.react-select__placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(156, 163, 175, 1) !important;
}

.react-select__input > input {
  background-color: #ffffff !important;
  box-shadow: none !important;
  border: none !important;
}

.react-select__input > input:focus {
  outline: none !important;
}

svg.poweredby-icon {
  fill: rgba(26, 26, 26, 0.4);
}

.tooltip-container {
  --tooltipBorder: rgba(0, 0, 0, 0.1);

  background-color: #fff;
  border: 0 solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(136 152 170 / 10%),
    0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%);
  position: relative;
  text-align: left;
  white-space: normal;
  word-break: normal;
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #4f566b;
}

.tooltip-arrow {
  width: 1rem;
  height: 1rem;
  position: absolute;
}

.tooltip-arrow::before {
  border-style: solid;
}

.tooltip-arrow::after {
  border-style: solid;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
  margin-top: -8px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::before {
  border-color: transparent transparent rgba(105, 115, 134, 0.3) transparent;
  border-width: 0 9px 10px 9px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::after {
  border-color: transparent transparent #fff transparent;
  border-width: 0 9px 10px 9px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
  margin-bottom: -15px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before {
  border-color: rgba(105, 115, 134, 0.3) transparent transparent transparent;
  border-width: 10px 9px 0 9px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::after {
  border-color: #fff transparent transparent transparent;
  border-width: 10px 9px 0 9px;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow {
  margin-left: -10px;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::before {
  border-color: transparent rgba(105, 115, 134, 0.3) transparent transparent;
  border-width: 9px 10px 9px 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::after {
  border-color: transparent #fff transparent transparent;
  border-width: 9px 10px 9px 0;
  left: 3px;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow {
  margin-right: -10px;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::before {
  border-color: transparent transparent transparent rgba(105, 115, 134, 0.3);
  border-width: 10px 0 10px 9px;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::after {
  border-color: transparent transparent transparent #fff;
  border-width: 10px 0 10px 9px;
  left: 4px;
}

.DayPicker {
  font-size: 12px;
  min-height: 283px;
  width: auto !important;
}

.DayPicker-wrapper {
  width: 300px !important;
  padding-bottom: 0;
}

.DayPicker-NavBar {
  position: relative;
  z-index: 2;
  height: 0;
}

.DayPicker-NavButton {
  position: absolute;
  cursor: pointer;
}

.DayPicker-NavButton--prev {
  border: 0;
  top: -5px;
  left: 0;
  padding: 0;
  margin: 0;
  height: 34px;
  width: 34px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iIzQyNDc3MCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNy4yODggNi4yOTZMMy4yMDIgMi4yMWEuNzEuNzEgMCAwMS4wMDctLjk5OWMuMjgtLjI4LjcyNS0uMjguOTk5LS4wMDdMOC44MDMgNS44YS42OTUuNjk1IDAgMDEuMjAyLjQ5Ni42OTUuNjk1IDAgMDEtLjIwMi40OTdsLTQuNTk1IDQuNTk1YS43MDQuNzA0IDAgMDEtMS0uMDA3LjcxLjcxIDAgMDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position: 50%;
  background-color: transparent;
  transform: rotate(180deg);
}

.DayPicker-NavButton--next {
  border: 0;
  top: -5px;
  right: 0;
  padding: 0;
  margin: 0;
  height: 34px;
  width: 34px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iIzQyNDc3MCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNy4yODggNi4yOTZMMy4yMDIgMi4yMWEuNzEuNzEgMCAwMS4wMDctLjk5OWMuMjgtLjI4LjcyNS0uMjguOTk5LS4wMDdMOC44MDMgNS44YS42OTUuNjk1IDAgMDEuMjAyLjQ5Ni42OTUuNjk1IDAgMDEtLjIwMi40OTdsLTQuNTk1IDQuNTk1YS43MDQuNzA0IDAgMDEtMS0uMDA3LjcxLjcxIDAgMDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position: 50%;
  background-color: transparent;
}

.DayPicker-Month {
  width: 100%;
  max-width: 100%;
  margin: 0 13px;
  margin-top: 0;
  background: transparent !important;
}

.DayPicker-Caption {
  color: #3c4257;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0;
  text-align: center;
  caption-side: top;
}

.DayPicker-Weekday {
  font-size: 11px;
}

.DayPicker-Day {
  color: #3c4257;
  font-size: 14px;
  font-weight: 500;
  margin-right: -1px;
  margin-bottom: -1px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #e3e8ee;
  border-radius: 0;
  background: #f7fafc;
  position: relative;
  text-align: center;
}

.DayPicker-Day--outside {
  background: none;
  border: none;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f7fafc;
}

.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #4f99ee;
  border-color: #4f99ee;
  color: #fff;
  z-index: 1;
}

.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #1678e9 !important;
  border-color: #1678e9 !important;
  color: #fff;
  z-index: 2;
}

.DayPicker-Day--disabled {
  background-color: #f7fafc;
  color: #a3acb9;
  cursor: default;
}

.rv-xy-plot__inner {
  z-index: 4;
}

@tailwind utilities;

@layer utilities {
  @responsive {
    .basis-0 {
      flex-basis: 0px;
    }
    .basis-11 {
      flex-basis: 2.75rem;
    }
    .basis-105 {
      flex-basis: 26.25rem;
    }
    .basis-full {
      flex-basis: 100%;
    }
    .filter-tear {
      filter: drop-shadow(0 2px 3px hsla(0, 0%, 0%, 0.07))
        drop-shadow(0 1px 0 hsla(0, 0%, 0%, 0.04));
    }
    .hyphens-auto {
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
    .padding-start-10 {
      padding-inline-start: 2.5rem;
    }
    .pointer-events-all {
      pointer-events: all;
    }
  }
}
